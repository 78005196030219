import performance from '../../content/performance'
import LinkComponent from '../LinkComponent/LinkComponent'
import Image from '../Image/Image'
import './PerformancePartnersSection.scss'

const { title, partner_cards } = performance.partners_section

const yellowConfettiBackground =
  'https://assets.cloud.pennylane.ai/pennylane_website/backgrounds/yellow_confetti_bg.png'

const PerformancePartnersSection = () => {
  /* Conditionally render section - if no partners or less than 3 don't render */
  if (!partner_cards || partner_cards.length < 3) return null

  /* Note: Current designs reflect only 3 cards - in the future this may be removed */
  const firstThreePartners = partner_cards.slice(0, 3)

  return (
    <div style={{ backgroundImage: `url(${yellowConfettiBackground})` }}>
      <section
        className="PerformancePartnersSection section-lg"
        data-testid="performance-partners-section"
      >
        <div className="PerformancePartnersSection__content">
          <div className="PerformancePartnersSection__title">
            <h2 className="text-center text-xl font-[600] text-grey-1">
              {title}
            </h2>
          </div>
          <div className="PerformancePartnersSection__cards">
            {firstThreePartners.map((card) => (
              <PerformancePartnerCard partner={card} key={card.description} />
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}

/* Partner Card */
interface IPerformancePartnerCardProps {
  partner: {
    link: string
    image: string
    description: string
  }
}

const PerformancePartnerCard = ({
  partner: { link, image, description },
}: IPerformancePartnerCardProps) => {
  return (
    <LinkComponent
      href={link}
      addClass="PerformancePartnerCard"
      data-testid="performance-partner-card"
    >
      <div className="PerformancePartnerCard__logo">
        <Image src={image} alt="" />
      </div>
      <div className="flex items-center">
        <p className="mb-0 font-roboto text-sm text-grey-1 pr-0 md:pr-2">
          {description}
        </p>
      </div>
      <div className="PerformancePartnerCard__icon">
        <i className="bx bx-chevron-right"></i>
      </div>
    </LinkComponent>
  )
}

export default PerformancePartnersSection
