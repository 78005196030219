import SubSectionLayout from '../SubSectionLayout/SubSectionLayout'

interface ICTALink {
  link_text: string
  link: string
}

interface ICTA {
  title: string
  description: string
  image: string
  image_right_aligned: boolean
  alt: string
  links: ICTALink[]
  id?: string
}

interface ICallToActionSectionProps {
  ctas: ICTA[]
  addClass?: string
}

const CallToActionSection = ({ ctas, addClass }: ICallToActionSectionProps) => {
  return (
    <section className={`mx-auto max-w-[1150px] w-full mb-16 ${addClass}`}>
      {ctas.map((action, index) => (
        <div key={`${action.title}-${index}`}>
          <SubSectionLayout {...action} />
        </div>
      ))}
    </section>
  )
}

export default CallToActionSection
